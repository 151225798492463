.approved-assignment {
  height: 100%;
}

.approved-assignment > .container {
  margin: 24px;
}

.approved-assignment {
  height: 100%;
}

.approved-assignment > .container {
  margin: 24px;
}

.approved-assignment-container {}

.approved-assignment-container > .approved-assignment-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.approved-assignment-container > .approved-assignment-headers > .filter-group {
  display: flex;
  column-gap: 10px;
}