.dr-secure-contact-list-wrapper {
  width: calc(100vw - 240px);
}

.dr-secure-contact-list-wrapper .module-page-title {
  padding-left: 26px;
  /* padding-bottom: 35px; */
}

.dr-secure-contact-list-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.dr-secure-contact-list-header .module-page-title {
  display: flex;
  flex-grow: 1;
}

.dr-secure-contact-list-header-right {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 8px;
  padding-right: 26px;
}

.dr-secure-contact-list-header-action {
  width: 128px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #781d7e;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  color: #781d7e;
}

.dr-secure-contact-list-header-icon {
  width: 46px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #781d7e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dr-secure-contact-list-column-action {
  width: 100px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 8px;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.dr-secure-contact-list-wrapper .k-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
}

.dr-secure-contact-list-wrapper .k-table-td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.dr-secure-contact-list-loader {
  display: flex;
  align-items: center;
  height: 50vh;
  justify-content: center;
}
