.filter-feedback {
  margin: 24px 2px
}

.filter-feedback > .filter-feedback__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-feedback > .filter-feedback__header > .filter-feedback__header-btb-clear{
  color: #F04A00;
  font-size: 14px;
  cursor: pointer;
}