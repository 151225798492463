.telephone-list-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  
  .telephone-list-header .module-page-title {
    display: flex;
    flex-grow: 1;
  }
  
  .telephone-list-header-right {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 8px;
    padding-right: 26px;
  }

  .telephone-list-header-icon {
    width: 46px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #781d7e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .telephone-dir-list-wrapper {
    width: 100%;
  }
  
  .telephone-dir-list-wrapper .module-page-title {
    padding-left: 26px;
  }

  .details-info {
    display: flex;
    gap: 50px;
    width: 100%;
    /* flex-flow: row wrap; */
    /* font-size: 14px; */
    padding-bottom: 20px;
    /* border-bottom: 1px solid var(--antiflash-white); */
  }

  .details-info > .details-info-left {
    width:500px;
    flex: 1;
    flex-flow: row wrap;
    column-gap: 50px;
    /* flex-grow: 1;
    background-color: #fff; */
    
  }
  .details-info__grp{
    margin-bottom: 15px;

  }

  .details-info .details-info-right {
  width: 500px;
  /* width:50%; */
  flex-flow: column;
  justify-content: flex-start;
  gap: 100px;
  /* padding-top: 24px;
  padding-right: 240px; */
}

.details-info-dropdown {
  margin: 10px 20px 20px 20px;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
}

.details-info--dropdown-value {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px;
  height: 48px;
}