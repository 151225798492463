.custom-step{
    width: 20px !important;
    height: 20px !important;
    margin-left: 7px !important;
    margin-top: -60px !important;
    font-family: Poppins,sans-serif !important;
    font-size: 16px !important;
}
.custom-audit-name{
    margin-bottom: 5px;
    width: 200px;
    white-space: initial;
    /* overflow: hidden;
    text-overflow: ellipsis; */
}
.custom-detail-item-body{
    display: flex;
    column-gap: 20px;
}
.custom-detail-item-label{
    width: 200px;
    padding: 10px;
    font-weight: 600;
    border-right: 2px solid var(--antiflash-white);
}
.custom-detail-item-value{
    padding: 10px;
}