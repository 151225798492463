.drTable{
  width: 100%;
}

.drTable td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background-color: #ffffff;
  border-right: 1px solid #D2DAE9 !important;
}
.k-grid-pager{
  justify-content: space-evenly !important;
 }

 .tbody-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  /* width: 1279px; */
  /* height: 710px; */
  border-radius: 0px 0px 0px 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
}