* {
  font-family: Poppins, sans-serif;
}
.detail-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  left: 260px;
  top: 80px;
  background: #ffffff;
  width: 1227px;
  height: 100px;
}
.detail-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: inherit;
  height: 37px;
  cursor: pointer;
  /* background-color: aqua; */
}
.categoryback-feedback-email-group-div {
  display: flex;
  flex-direction: row;
  width: 86px;
  cursor: grab;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.categoryback-label {
  /* position: absolute;
    width: 54px;
    height: 28px;
    left: 26px;
    top: 4.5px;
*/
  color: #781d7e;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
.categoryback-icon {
  width: 13px;
  height: 24px;
}
.editBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 115px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
}
.editBtn-label {
  font-weight: 600;
  color: #666666;
  font-size: 16px;
  width: 31px;
  font-style: normal;
  line-height: 130%;
  padding: 0px 8px;
}

.detail-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: inherit;
  height: auto;
  padding-top: 20px;
}
.detail-content table {
  width: inherit;
}
.detail-content td {
  padding: 9px;
  vertical-align: top;
}
.detail-content td:first-child {
  width: 200px;
  font-weight: 600;
  color: #333;
  font-size: 16px;
  border-right: 1px solid #cccccc;
}
.detail-sound-div {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.detail-sound-div img {
  vertical-align: middle;
}

.ack-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 7px;
  width: 420px;
  height: 24px;
}
.ack-radio-btn {
  border: 1px solid #cccccc;
}
.detail-file-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.detail-file-name {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-radio-div {
  width: 130px;
  display: flex;
  justify-content: space-between;
}
.detail-radio-div .k-radio {
  border: 1px solid #ccc;
}
.detail-button {
  width: 180px;
  height: 50px;
  border-radius: 8px;
}
.detail-button-div {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
.detail-cancel {
  background-color: #ffffff !important;
  color: #333;
  border: 1px solid #333 !important;
}
.detail-submit {
  background-color: #eb6e1f !important;
  color: #ffffff !important;
  border: 1px solid #eb6e1f !important;
}
.detail-cancel:hover {
  background-color: #ffffff !important;
}
.detail-submit:hover {
  background-color: #eb6e1f !important;
}
.edit-depot-layout {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.edit-depot-layout Button {
  width: 250px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #333;
}
.edit-depot-layout Button:hover {
  background-color: #fff;
}

.edit-category-layout {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.edit-category-layout Button {
  width: 280px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #333;
}
.edit-category-layout Button:hover {
  background-color: #fff;
}

.isafe_form {
  width: 50%;
  flex-flow: row wrap;
  /* font-size: 14px; */
  padding-left: 20px;
  flex-direction: row;
}
.isafe-button-div {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  padding-top: 20px;
}
.feedback-email-group-users-edit-permission-layout {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.feedback-email-group-users-edit-permission-layout Button {
  width: 350px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #333;
}
.feedback-email-group-users-edit-permission-layout Button:hover {
  background-color: #fff;
}
.feedback-email-group-users-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.feedback-email-group-users-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.feedback-email-group-users-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.feedback-email-group-users-submit-button:hover {
  background-color: #eb6e1f;
}
.feedback-email-group-users-dialog Button {
  height: 40px;
}
.feedback-email-group-users-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.category-details-alert-form {
  font-family: Poppins, sans-serif;
}
.category-details-alert-form .k-window.k-dialog {
  min-width: 400px;
}
.category-details-alert-form p {
  font-size: 16px;
  justify-content: center;
  text-align: center;
}
.category-details-alert-form li {
  padding: 5px 0px;
}
