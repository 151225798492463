:root {
  --antiflash-white: #eff0f3ff;
  --golden-gate-bridge: #ee4a02ff;
  --purple: #780982ff;
  --platinum: #e7e8e9ff;
  --white: #ffffffff;
  --seasalt: #f6f6f6ff;

  --jasmine: #ffdf8eff;
  --silver: #a8a8a8ff;
  --gray: #818284ff;
  --red-dot: rgb(233, 70, 94);
}

.navigation-div {
  width: 100% !important;
}

.wrapper_MIC {
  display: flex;
  width: 100%;
}

.wrapper_MIC > .mic-page-content {
  width: 75%;
  margin-left: 20px;
  margin-top: 20px;
}

.wrapper_MIC > .mic-page-sidebar {
  width: 25%;
  background-color: var(--seasalt);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.mic-details__headers {
  display: flex;
}

.mic-details__body {
  margin-top: 20px;
}

.router-back {
  display: flex;
  column-gap: 10px;
  cursor: pointer;
}

.router-back > .router-back__label {
  font-size: 18px;
  font-weight: bold;
  color: var(--pomp-and-power);
}

.mic-details-info {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--antiflash-white);
}

.mic-details-info > .mic-details-info__item {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.mic-details-info > .mic-details-info__item > .mic-details-info__desc {
  width: 25%;
  padding: 10px 0px;
  font-weight: 500;
  border-right: 2px solid var(--antiflash-white);
}

.mic-details-info > .mic-details-info__item > .mic-details-info__value {
  width: 75%;
  padding: 10px 0px;
}

.mic-details-comments {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.mic-details-comments > .mic-details-comments__title {
  font-weight: bold;
}

.mic-details-comments > .mic-details-comments__comments {
  margin-top: 20px;
  padding: 20px 15px;
  background-color: var(--seasalt);
}

.comment-wrapper {
  max-height: 300px;
  overflow-y: scroll;
}

.comment-item {
  position: relative;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}

.comment-item > .toggle-comment {
  position: absolute;
  right: 20px;
  top: 50%;
}

.comment-item > .owner-name {
  font-weight: bold;
  margin: 6px 0px;
}

.comment-item > .date-time-comment {
  margin: 6px 0px;
  font-size: 14px;
}

.comment-item > .date-time-content {
  margin: 6px 0px;
  font-size: 16px;
}

.comment-add {
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
}

.comment-add > .comment-add__label {
  margin: 10px 0px;
}

.comment-add > .comment-add__textbox {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: 1px solid var(--platinum);
}

.comment-add > .comment-add__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-add {
  background-color: var(--golden-gate-bridge);
  color: white;
  border: none;
  width: 60px;
  height: 35px;
  border-radius: 4px;
}

.button-primary {
  background-color: var(--golden-gate-bridge);
  color: white;
  border: none;
  width: 100%;
  min-height: 30px;
  font-weight: bold;
}

.card {
  background-color: white;
  padding: 10px;
  margin: 10px 0px;
}

.content-label {
  font-size: 14px;
  font-weight: bold;
}

.label-status {
  margin-top: 10px;
  padding: 5px;
  font-size: 14px;
  background-color: var(--jasmine);
  border-radius: 5px;
  font-weight: bold;
}

.label-action {
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  color: var(--gray);
  font-weight: bold;
  border: 1px solid var(--gray);
  cursor: pointer;
}

/*  */
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;

  max-height: 400px;
  overflow-y: scroll;
}
.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: calc(100% - 30px);
}
.StepProgress-item {
  position: relative;
  counter-increment: list;
  font-size: 14px;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -26px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}

.StepProgress-item.is-done::before {
  border-left: 2px solid var(--golden-gate-bridge);
}

.StepProgress-item.is-done::after {
  content: "";
  font-size: 13px;
  color: #fff;
  text-align: center;
  border: 2px solid var(--golden-gate-bridge);
  background-color: var(--golden-gate-bridge);
}
.StepProgress-item.current::before {
  border-left: 2px solid var(--golden-gate-bridge);
}
.StepProgress-item.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 25px;
  height: 25px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  color: var(--platinum);
  border: 2px solid var(--platinum);
  background-color: var(--platinum);
}
.StepProgress strong {
  display: block;
}

.StepProgress-item > div {
  margin: 6px 0px;
}

.StepProgress-item > .content {
  font-weight: bold;
}

.dropdown {
}

.dropdown > .dropdown-label {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown > .dropdown-select {
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid var(--platinum);
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
