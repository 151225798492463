.Articles_detail_main_box{
    height: calc(100% - 75px);
}

.Articles_detail_child_box{
    display: flex;
    height: 100%;
}

.Articles_detail_left_box{
    flex: 1 1 64%;
    padding: 20px 0px 0px 20px
}

.Articles_detail_back{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.Articles_detail_child_box_main_detail_box{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: calc(100% - 60px);
    padding-right: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Articles_detail_child_box_main_detail_items{
    display: flex;
    gap: 20px;
}

.Articles_detail_child_box_main_detail_items_title{
    width: 230px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.Articles_detail_center_item{
    align-items: center;
    display: flex;
}

.detail_padding_bottom{
    padding-bottom: 15px;
    padding-top: 15px;
}

.Articles_detail_child_box_main_detail_items_splitter{
    width: 1px;
    background: #CCCCCC;
}

.Articles_detail_child_box_main_detail_items_subdetail{
    flex: 1;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}

.Articles_detail_right_box{
    flex: 1 1 35%;
    overflow: scroll;
    background: #F5F7FA;
    padding: 20px;
    max-width: 350px;
    min-width: 270px
}

.Articles_detail_right_status_box{
    background: white;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.Articles_detail_right_box_status_title{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.Articles_detail_right_box_status_subdetail{
    background: #36D49B;
    color: white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    border-radius: 8px;
    max-width: 110px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Articles_detail_right_box_action_main_box{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 8px;
    padding: 20px;
}

.Articles_detail_right_box_action_title{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.Articles_detail_right_box_action_edit{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:13px 20px;
    max-width: 200px;
    cursor: pointer;
}


.Articles_detail_right_box_action_delete{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    /* height: 48px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    max-width: 220px;
    cursor: pointer;
}

.audit_bar_hide .k-stepper .k-progressbar-vertical{
    display: none;
}

.Articles_create_header{
    margin: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Articles_create_back{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.Articles_steps {
  width: 50%;
  left: 25%;
  /* color: #CCCCCC; */
}
.Articles_steps .k-step-indicator {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.Articles_steps .k-step-list.k-step-list-horizontal{
    gap: 25px;
}

.Articles_steps .k-progressbar.k-progressbar-horizontal{
    top: 13px
}

.Articles_create_scroll_box{
    height: calc(100vh - 315px);
    overflow: scroll;
    padding: 0px 20px;
    margin-top: 20px;
}

.Articles_create_item_main_box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.Articles_create_item_main_box_title{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: black;
}

.Articles_create_item_main_box_title_span{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.Articles_create_item_main_box_field{
    border: 1px solid #CCCCCC;
}

.Articles_create_autosaved_icon{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border: 1px solid #E7ECEF;
    border-radius: 8px;
    background: #E7ECEF;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    gap: 8px;
}

.Articles_create_dropdown{
    flex: 1;
}

.k-i-arrow-chevron-down{
    color: #73007D;
}

.Articles_create_dropdown .k-dropdownlist {
    min-height: 48px;
    background-color: white;
}

.Articles_create_dropdown .react-datepicker-wrapper{
    width: 100%;
}

.Articles_create_date_main_box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: #F6F6F6;
    padding: 20px;
}

.Articles_create_date_child_box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
}

.Articles_create_date_child_box_title{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
}

.Articles_create_date_child_box_field_box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Articles_create_date_child_box_field_box_item{
    flex: 1;
}

.Articles_create_error_text{
    margin-top: 10px;
    font-style: italic;
    color: red;
    font-size: 12px;
}

.Articles_create_date_child_box_field_box_item .react-datepicker-wrapper{
    width: 100%;
}

.Articles_create_upload_main_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.Articles_create_upload_button_box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 0px 20px;
    background: #73007D;
    color: white;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    cursor: pointer;
}

.Articles_create_footer_main_box{
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: calc(100% - 40px);
    background: white;
    box-shadow: 0px -1px 0px 0px #00000040;
    padding: 15px 20px;
}

.Articles_create_footer_button_previous{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: white;
    color: #F04A00;
    cursor: pointer;
}

.Articles_create_footer_button_publish{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #73007D;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #73007D;
    color: white;
    cursor: pointer;
}

.Articles_create_footer_button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: white;
    background: #F04A00;
    cursor: pointer;
}

.Articles_create_review_main_box{
    display: flex;
    gap: 10px;
}

.Articles_create_scroll_box_review{
    height: calc(100vh - 335px);
    overflow: scroll;
    padding: 0px 20px 20px;
    margin-top: 20px;
}

.Articles_create_review_left{
    flex: 1;
    max-width: 250px;
    display: flex;
    align-items: center;
}

.Articles_create_review_middle{
    height: auto;
    width: 1px;
    background: #CCCCCC;
}

.Articles_create_review_right{
    flex: 1;
}

.Articles_create_review_flex{
    display: flex;
    align-items: center;
}

.Articles_detail_edit{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #666666;
    padding: 5px 25px;
    border-radius: 8px;
    gap: 10px;
    cursor: pointer;
}

.Articles_create_file_main_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 10px;
}

.Articles_create_file_child_box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Articles_create_file_image{
    max-width: 150px;
    max-height: 100px;
}

.Articles_create_file_child_box_text{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
}

.Articles_create_file_child_box_delete_icon{
    font-size: 24px;
    color: #EB7930;
    cursor: pointer;
}

.Articles_filter_main_box{
    min-width: 300px;
    min-height: 400px;
    /* padding: 20px; */
}

.Articles_filter_cross_icon{
    position: absolute;
    right: 0px;
    color: #73007D;
    cursor: pointer;
}

.Articles_filter_header_box{
    padding: 40px 20px 0px;
    display: flex;
    justify-content: space-between;
}

.Articles_filter_header_title{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
}

.Articles_filter_header_clear_filter{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.03em;
    text-align: left;
    text-decoration: underline;
    color: #F04A00;
    cursor: pointer;
}

.Articles_filter_detail_main_box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    height: calc(100vh - 200px);
    overflow: scroll;
    padding: 0px 20px 20px;
}

.Articles_filter_detail_date_box{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Articles_filter_detail_title{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.Articles_filter_radio_button_main_box{
    display: flex;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    justify-content: space-between;
    color: #333333;
}

.Articles_filter_detail_date{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.Articles_filter_detail_date_child{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}

.Pormotions_filter_check_box_main_box{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Pormotions_filter_check_box_item{
    display: flex;
    gap: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.Articles_filter_dialog{
    align-items: flex-end;
}

.Articles_filter_Radio {
  border: 1px solid #cccccc;
}

.Articles_filter_radio_button_main_box input[type="radio"]:checked{
    border-color: #781D7E;
    border-width: 3px;
}

.Articles_filter_radio_main_box{
    display: flex;
    align-items: center;
    gap: 10px;
}

.Articles_filter_footer_main_box{
    justify-content: space-between;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.Articles_filter_footer_cancel{
    flex: 1;
    color: #F04A00;
    border: 1px solid #F04A00;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.Articles_filter_footer_submit{
    flex: 1;
    color: white;
    background: #F04A00;
    border: 1px solid #F04A00;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.Articles_clear_date{
    text-decoration: underline;
    color: blue;
    font-size: 13px;
    float: right;
    cursor: pointer;
}

.Articles_doc_viewer{
    border: 1px solid #6666;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}

.Articles_doc_viewer_dialog{
    width: 46vw;
    height: 85vh;
    overflow: hidden;
    padding: 0px 20px 20px;
}

.Articles_doc_viewer_dialog_item{
    height: 100%;
    margin-top: 20px;
}

.Articles_doc_viewer_dialog_close{
    position: absolute;
    right: -20px;
    z-index: 9;
    top: 0;
    cursor: pointer;
}

.Articles_doc_viewer_dialog_item_actoin{
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}