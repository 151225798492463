.reusable_header_main_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 30px;
}

.reusable_header_title{
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 36.48px;
    text-align: left;
    color: #333333;
}

.reusable_header_icon_main_box{
    display: flex;
    gap: 10px;
}

.reusable_header_create_box{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #73007D;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #73007D;
    border-radius: 10px;
    padding: 0px 20px;
    cursor: pointer;
}

.reusable_header_icon_box{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #73007D;
    border-radius: 10px;
    height: 48px;
    padding: 0px 14px;
    cursor: pointer;
}

.reusable_table_button_dropdown{
    font-size: 15px;
    height: 48px;
    color: #333333;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #333333;
    background: white;
}

.reusable_table_button_dropdown:active,
.reusable_table_button_dropdown:focus-within{
    background-color: #F04A00;
    color: white;
    border-color: #F04A00;
}

.reusable_table_pagination_main_box{
    position: fixed;
    bottom: 0;
    width: 100%;
    /* width: calc(100% - 240px); */
    background-color: #F6F6F6;
}

.Table_header {
  gap: 10px;
  display: flex;
  align-items: center;
  height: 40px;
}

.TableHandle {
  height: 60px;
  background: white;
  cursor: pointer;
}

.TableHandle:hover {
  background-color: #fafafa;
}

.Table_button {
  background: #eb7930;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  max-width: 155px;
  cursor: pointer;
}

.table-select-custom table {
  width: 100%;
  text-align: left;
  border: 0;
  background: #efefef;
  border-collapse: collapse;
}

.table-select-custom th,
.table-select-custom td {
  padding: 0px 16px;
    border-width: 0px 1px;

}

.table-select-custom th {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
}

.table-select-custom td {
    max-width: 100px;
    overflow: hidden;
    font-family: Poppins;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #776f6a;
}

#table {
  max-width: calc(100vw - 260px);
  overflow-x: auto;
}

/* width */
#table::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#table::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
#table::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

/* Handle on hover */
#table::-webkit-scrollbar-thumb:hover {
  background: grey;
}


.scrolledDIV::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.scrolledDIV::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.scrolledDIV::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.scrolledDIV::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.Dialog_cross_button{
    position: absolute;
    right: 0px;
    top: 0;
    cursor: pointer;
}

.Dialog_main_box{
    margin: 20px;
    width: 350px;
}

.Dialog_detail_box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.Dialog_detail_title{
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.Dialog_detail_subTitle{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.Dialog_button_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    gap: 20px;
}

.Dialog_button_cancel{
    flex: 1;
    color: #F04A00;
    border: 1px solid #F04A00;
    padding: 15px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Dialog_button_confirm{
    flex: 1;
    color: white;
    border: 1px solid #F04A00;
    padding: 15px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #F04A00;
}

.search-div {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 0px 5px 10px;
}

.search-div .textbox {
    border: 0px !important;
    border-radius: 0;
    height: 48px;
    flex-grow: 1;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
}

.search-div .textbox:focus {
    outline-width: 0;
}

.Search_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.Search-reset-icon {
    cursor: pointer;
}

.No_record {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
}

.Permission_Denined_box{
    display: flex;
    align-items: center;
    justify-content: center;
}