.navigation-div {
  width: 100% !important;
}

.body {
  display: block;
  width: 100%;
}

.mic-popup__wrapper {
  width: 100%;
  min-width: 300px;
}

.mic-popup__wrapper > .button-close-popup {
  float: right;
  cursor: pointer;
}

.mic-popup__wrapper > div > .dropdown > .footer-section {
  margin-top: 20px;
}

.input-area {
  width: calc(100% - 6px);
  border: 1px solid var(--platinum);
  height: 50px;
}

.tabs {
  margin: 10px 26px;
  display: flex;
  column-gap: 10px;
}

.tabs > .tab {
  cursor: pointer;
  font-size: 20;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.tabs > .tab.isactive {
  color: #F04A00;
}

.MIC_delete_popup_box{
  height: 180px;
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    /* gap: 70px; */
    margin-bottom: 25px;
}

.MIC_delete_title{
  /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
}

.MIC_delete_footer_box{
  width: calc(100% - 60px);
    margin: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.MIC_delete_footer_cancel{
  color: #71277a;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MIC_delete_footer_delete{
  color: white;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    background: #71277a;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}