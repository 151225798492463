.container {
    width: 100%;
}

.filter_main_box{
    display: flex;
    gap: 20px;
    margin: 0px 20px 20px;
    align-items: end;
}

.filter_child_box{
    flex: 1;
}

.k-i-arrow-chevron-down{
    color: #73007D;
}

.filter_child_box .k-dropdownlist {
    min-height: 48px;
    background-color: white;
}

.filter_child_box .react-datepicker-wrapper{
    width: 100%;
}

.filter_button{
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #F04A00;
    min-height: 48px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.filter_title{
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    margin-bottom: 10px;
}

.eCards_detail_main_box{
    /* margin: 20px; */
    height: calc(100% - 75px);
}

.eCards_detail_child_box{
    display: flex;
    /* gap: 20px; */
    /* margin-top: 20px; */
    /* height: calc(100% - 55px); */
    height: 100%;
}

.eCards_detail_right_box{
    flex: 1 1 35%;
    overflow: scroll;
    background: #F5F7FA;
    padding: 20px;
    max-width: 350px;
    min-width: 270px
}

.eCards_detail_right_status_box{
    background: white;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.eCards_detail_right_box_status_title{
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.eCards_detail_right_box_status_subdetail{
    background: #36D49B;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    border-radius: 8px;
    max-width: 110px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eCards_detail_right_box_action_main_box{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 8px;
    padding: 20px;
}

.eCards_detail_right_box_action_title{
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #333333;
}

.eCards_detail_right_box_action_edit{
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    /* height: 48px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:13px 20px;
    max-width: 200px;
    cursor: pointer;
}

.eCards_detail_right_box_action_delete{
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    color: #666666;
    border: 1px solid #666666;
    /* height: 48px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    max-width: 220px;
    cursor: pointer;
}

.eCards_detail_right_box_action_edit:hover,
.eCards_detail_right_box_action_delete:hover{
    background-color: #f7f4f4;
}

.eCards_detail_left_box{
    flex: 1 1 64%;
    /* overflow: scroll; */
    padding: 20px 0px 0px 20px
}

.eCards_detail_back{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}


.eCards_detail_child_box_main_detail_box{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: calc(100% - 60px);
    padding-right: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.eCards_detail_child_box_main_detail_items{
    display: flex;
    gap: 20px;
    /* min-height: 55px; */
}

.eCards_detail_child_box_main_detail_items_title{
    width: 230px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.eCards_detail_center_item{
    align-items: center;
    display: flex;
}

.detail_padding_bottom{
    padding-bottom: 15px;
    padding-top: 15px;
}

.eCards_detail_child_box_main_detail_items_splitter{
    width: 1px;
    background: #CCCCCC;
}

.eCards_detail_child_box_main_detail_items_subdetail{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #333333;
}

.eCards_detail_sender_by {
    color: #781d7e;
    padding: 15px 0px;
    font-weight: 700;
}