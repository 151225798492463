.page-wrapper {
  height: 100%;
}

.page-wrapper > .container {
  margin: 24px;
}

.assignment-container {}

.assignment-container > .assignment-container-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.assignment-container > .assignment-container-headers > .filter-group {
  display: flex;
  column-gap: 10px;
}