.checker-preference {
  height: 100%;
}

.checker-preference > .container {
  margin: 24px;
}

.checker-preference-container {}

.checker-preference-container > .checker-preference-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checker-preference-container > .checker-preference-headers > .filter-group {
  display: flex;
  column-gap: 10px;
}

th {
  text-align: center;
}

.form-group-approval {
  display: grid;
  column-gap: 12px;
  grid-template-columns: auto auto 100px;
}

.form-group-approval-no-btn-approval {
  display: grid;
  column-gap: 12px;
  grid-template-columns: auto auto;
}

.button-approved {
  width: 100%;
  height: 100%;
}