.search-notification {
  align-items: flex-end !important;
  font-family: Poppins, sans-serif;
}

.search-notification .k-dialog-titlebar {
  border: none;
  background-color: white;
}
.noti-dialog-title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #333333;
}
.dialog-row:first-child {
  margin-top: 0px;
}
.dialog-row {
  margin-top: 18px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
}
.search-row-title {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
.search-input {
  margin-top: 9px;
  height: 48px;
  width: 320px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: none;
}
.search-input:focus {
  box-shadow: none;
}
.search-button {
  padding: 0px 18px 0px 24px;
  height: 48px;
  width: 170.5px;
}
.search-notification .k-dialog-actions {
  padding-inline: 20px;
  padding-block: 20px;
}
.cancel-btn {
  background-color: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 8px;
  color: #eb6e1f;
}
.cancel-btn:hover {
  background-color: #ffffff;
}
.search-btn {
  background-color: #eb6e1f;
  border-radius: 8px;
  color: #ffffff;
}
.search-btn:hover {
  background-color: #eb6e1f;
}
.filter-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  font-family: Poppins, sans-serif;
}
.clear-filter {
  color: #eb6e1f;
  cursor: pointer;
  letter-spacing: 0.07em;
}
.title {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
}
.filter-body {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
}
.filter-sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  font-style: normal;
}
.filter-category-group {
  list-style-type: none;
  float: left;
  gap: 8px;
  padding: 0px;
  align-items: center;
}
.filter-category-item {
  float: left;
  margin-top: 10px;
  background-color: #eb6e1f;
  box-sizing: border-box;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 158px;
  height: 79px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
}
.filter-category-item:nth-child(even) {
  margin-left: 10px;
}
.category-flex-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
  /* flex-direction: column; */
}
.filter-option-input {
  border: 1px solid #cccccc;
}
.date-option-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 152px;
  height: 24px;
}
.category-date-div {
  color: #000000;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px 0px; */
}
.filter-date-range {
  border: 1px solid #cccccc;
  border-radius: 8px;
}
.filter-date-range button {
  color: #781d7e;
  background-color: #ffffff;
  border: none;
}
.filter-status-group {
  list-style-type: none;
  float: left;
  gap: 8px;
  padding: 0px;
  align-items: center;
  margin-top: 0px;
}
.filter-status-item {
  float: left;
  margin-top: 10px;
  background-color: #eb6e1f;
  box-sizing: border-box;
  align-items: center;
  gap: 8px;
  width: 50%;
  background: #ffffff;
  display: flex;
  flex-direction: row;
}
.clear-button-dialog {
  border: none !important;
  background-color: #ffffff !important;
  color: #eb6e1f !important;
  font-size: 12px !important;
}

.clear-button-dialog:hover {
  background-color: #ffffff !important;
  border: none !important;
}

.user-filter-dropdown-multi {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  color: #000000;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.user-filter-select-multi {
  position: relative;
  width: 100%;
}

.k-animation-container {
  z-index: 10003 !important;
}

.filter-error {
  color: #ff0000;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 0.5em 0 0.5em 0;
}
