.summary-count {
  display: flex;
  column-gap: 16px;
  margin: 20px 0px;
}

.summary-count > .card {
  color: #781D7E;
  text-align: center;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  padding: 42px;
  min-width: 160px;
}

.summary-count > .card > .result {
  margin-top: 4px;
  margin-bottom: 4px;
}

.summary-count > .card > .title {
  font-weight: bold;
  color: #781D7E;
}