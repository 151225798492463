.dr-secure-content-details-wrapper {
  display: flex;
  flex-flow: column;
  width: calc(100vw - 240px);
  /* justify-content: space-between;
  gap: 25px; */
  background-color: #fff;
}

.dr-secure-contact-content-header {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; */
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
}

.dr-secure-contact-content-header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #781d7e;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dr-secure-contact-content-header-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 89px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #666666;
  border-radius: 8px;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #666666;
  cursor: pointer;
}

.dr-secure-contact-content-details {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 38px;
}

.dr-secure-contact-content-details-left {
  /* width: 25%; */
  border-right: 1px solid #cccccc;
  margin-right: 26px;
  padding-right: 26px;
  padding-left: 32px;
}

.dr-secure-contact-content-details-left-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #333333;
  height: 21px;
}

.dr-secure-contact-content-details-left-label.normal {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 48px;
}

.dr-secure-contact-content-details-left-label.normal:nth-last-child(1) {
  margin-bottom: 0px !important;
}

.dr-secure-contact-content-details-left-label.description {
  margin-bottom: 58px;
}

.dr-secure-contact-content-details-left-label.attachment {
  margin-bottom: 105px;
}

.dr-secure-contact-content-details-right {
  /* flex-grow: 1; */
  /* width: 60%; */
  padding-right: 26px;
}

.dr-secure-contact-content-details-right-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}

.dr-secure-contact-content-details-right-label.normal {
  /* height: 19px; */
  margin-bottom: 16px;
}

.dr-secure-contact-content-details-right-label.normal:nth-last-child(1) {
  margin-bottom: 0px !important;
}

.dr-secure-contact-content-details-right-label.description {
  height: 58px;
  margin-bottom: 38px;
}

.dr-secure-contact-content-details-right-label.attachment {
  height: 105px;
  margin-bottom: 10px;
}

.dr-secure-contact-content-details-hr {
  border-top: 1px solid #cccccc;
  margin-top: 24px;
  margin-bottom: 24px;
}

.dr-secure-contact-content-details-input-description {
  height: 80px !important;
}

.dr-secure-contact-content-header-btn-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 48px;
  background: #eb6e1f;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  margin-left: 32px;
  margin-top: 26px;
}

.dr-secure-contact-details-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
