.container {
    width: 100%;
}

.filter_main_box {
    display: flex;
    gap: 20px;
    margin: 0px 20px 20px;
    align-items: end;
}

.filter_child_box {
    flex: 1;
}

.k-i-arrow-chevron-down {
    color: #73007D;
}

.filter_child_box .k-dropdownlist {
    min-height: 48px;
    background-color: white;
}

.filter_child_box .react-datepicker-wrapper {
    width: 100%;
}

.filter_button {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #F04A00;
    min-height: 48px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.filter_title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    margin-bottom: 10px;
}

.upload-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F04A00;
    border: 1px solid #F04A00;
    border-radius: 8px;
    color: white;
    gap: 5px;
    height: 50px;

}


.upload-btn:hover {
    background: #F04A00;
    opacity: 0.7;
}


.clinic_filter_dialog{
    align-items: flex-end;
}

.clinic_filter_Radio {
  border: 1px solid #cccccc;
}

.clinic_filter_radio_button_main_box input[type="radio"]:checked{
    border-color: #781D7E;
    border-width: 3px;
}

.clinic_filter_radio_main_box{
    display: flex;
    align-items: center;
    gap: 10px;
}

.clinic_filter_footer_main_box{
    justify-content: space-between;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #333333;
}

.clinic_filter_footer_cancel{
    flex: 1;
    color: #F04A00;
    border: 1px solid #F04A00;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.clinic_filter_footer_submit{
    flex: 1;
    color: white;
    background: #F04A00;
    border: 1px solid #F04A00;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}



.clinic_child_box_main_detail_items {
    display: flex;
    width: 30vw;
    /* min-height: 55px; */
    gap: 20px;
}

.clinic_child_box_main_detail_items_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: #73007D;
}

.tutorial_detail_center_item {
    align-items: center;
    display: flex;
}

.detail_padding_bottom {
    padding-bottom: 15px;
    padding-top: 15px;
}

.clinic_child_box_main_detail_items_splitter {
    width: 1px;
    background: #CCCCCC;
}

.clinic_template_button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background: white;
    border: 1px solid #73007D;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    padding: 0 12px;
    font-size: 14px;
}

.margin-top{
    margin-top: 20px;
}

.padding-top{
    padding-top: 10px;
}

.clinic_template_button .child {
    display: flex;
    gap: 10px;
    align-items: center;
}

.button-text {
    flex-grow: 1;
    color: #73007D;
    font-weight: 600;
}



.right-button-text {
    flex-grow: 1;
    color: #73007D;
    font-weight: 600;
    text-align: right;
    cursor: pointer;
}


.clinic_upload_layout{
    color: white;
    background: #73007d11;
    border: 1px solid #CCCCCC;
    height: 100px;
    border-radius: 2px;
    padding: 0 12px;
    font-size: 14px;
}


.clinic_upload_layout_button{
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    font-size: 14px;
}


.clinic-submit-button {
    background-color: #eb6e1f;
    border: "1px solid #EB6E1F";
    color: #fff;
    height: 40px;
}

.clinic-submit-button:hover {
    background-color: #eb6e1f;
}


.clinic-submit-button:disabled {
    background-color: white;
    border: 1px solid #333;
    color: black;
    height: 40px;
}



.clinic-cancel-button {
    background-color: #fff;
    border: 1px solid #333;
    height: 40px;
}



.slected-file-text {
    margin-top: 20px;
    color: black;
    font-weight: 600;
    font-size: 12px;
}
