table {
  width: 100%;
  color: #666666;
}

thead > tr > th {
  background: #F6F6F6;
  border-color: #CCCCCC;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 6px;
  border-color: #CCCCCC;
}

.list-of-checker-container {}

.list-of-checker-container > .list-of-checker-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-of-checker-container > .list-of-checker-headers > .filter-group {
  display: flex;
  column-gap: 10px;
}

.custom-button{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  /* width: 46px; */
  /* height: 48px; */
  background: #FFFFFF !important;
  border: 1px solid #781D7E !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px !important;
  flex: none;
  order: 2;
  flex-grow: 0;

  background-color: white !important;
  box-shadow: none;
  padding: 10px;
  max-height: 38px;
}
.custom-button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-submit {
  background-color: #780982ff;
  color: white;
  outline: none;
  border: 1px solid #780982ff;
  padding: 6px;
  cursor: pointer;
}

.button-cancel {
  border: 1px solid #780982ff;
  background-color: white;
  outline: none;
  padding: 6px;
  cursor: pointer;
}

.datepicker-wrapper {
  width: 100%;
}

.datepicker-wrapper > .react-datepicker-wrapper {
  width: 100%;
}