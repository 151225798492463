.ecards-filter-options{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:20px;
}


.notification-details__body {
    margin-top: 20px;
  }
  
  .notification-details-info {
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--antiflash-white);
  }
  
  .notification-details-info > .notification-details-info__item {
    width: 100%;
    display: flex;
    column-gap: 20px;
  }
  
  .notification-details-info
    > .notification-details-info__item
    > .notification-details-info__desc {
    width: 25%;
    padding: 10px 0px;
    font-weight: 500;
    border-right: 2px solid var(--antiflash-white);
  }
  
  .notification-details-info
    > .notification-details-info__item
    > .notification-details-info__value {
    width: 75%;
    padding: 10px 0px;
  }

  .sending-by-div{
    color:#781d7e;
    padding: 10px 0px;
    font-weight: 700;
  }
  .status-label::first-letter{
    text-transform: capitalize;
  }

  .ecard-approve-btn{
    background-color: #eb6e1f;
    color: #fff;
    padding: 10px 20px;
  }
  .ecard-approve-btn:hover{
    background-color: #eb6e1f;
    color: #fff;
  }