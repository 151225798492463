.policy_doc_main_box{
    display: flex;
    width: calc(100% - 40px);
    gap: 30px;
    /* height: 40px; */
    align-items: center;
    padding: 0px 20px;
    margin-top: 20px;
    justify-content: space-around;
}

.policy_doc_upload_main_box{
    display: flex;
    width: 50%;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
}

.policy_doc_button{
    height: 40px;
    width: 150px;
    background: #781d7e;
    color: white;
    border: 1px solid #781d7e;
    border-radius: 10px;
    cursor: pointer;
}

.policy_doc_view{
    /* width: 50%; */
    text-decoration: underline;
    cursor: pointer;
}

.policy_doc_popup_main_box{
    width: 300px;
    height: 150px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.policy_doc_popup_button{
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #781d7e;
    background: #781d7e;
    color: white;
    border-radius: 10px;
}