.react-datepicker-popper {
  z-index: 10000;
}

.Feedback_delete_popup_box{
  height: 180px;
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    /* gap: 70px; */
    margin-bottom: 25px;
}

.Feedback_delete_title{
  /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
}

.Feedback_delete_footer_box{
  width: calc(100% - 60px);
    margin: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Feedback_delete_footer_cancel{
  color: #71277a;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Feedback_delete_footer_delete{
  color: white;
    /* font-family: "Montserrat"; */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    width: calc(50% - 10px);
    border: 1px solid #71277a;
    background: #71277a;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}