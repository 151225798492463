.pagination_main_box{
    display: flex;
    align-items: center;
    justify-content: left;
    /* justify-content: center; */
    height: 34px;
    gap: 10px;
    padding: 10px 20px;
    border: 1px solid rgb(205, 213, 230);
    background: rgb(250, 250, 250);
}

.pagination_item_box_unactive{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #FFFFFF; */
    /* border-radius: 8px; */
    cursor: pointer;
    font-family: Poppins,sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    color: rbg(140, 140, 140);
    border: 1px solid white;
}

.pagination_item_box_active{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(222, 207, 224);
    /* border-radius: 8px; */
    cursor: pointer;
    font-family: Poppins,sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: rgb(103, 0 116);
}

.pagination_item_box_unactive:hover{
    background-color: rgb(105, 17, 121);
    color: white;
}

.pagination_item_row_count_main_box{
    margin-left: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.pagination_item_row_count_title{
    font-family: Poppins,sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    color: #424242;
}

.pagination_item_row_count_drop_down {
    height: 36px;
    width: 80px;
    background-color: #FFFFFF !important;
    box-shadow: none;
    border: 1px solid #E4E1DE !important;
    background-image: none !important;
    border-radius: 8px !important;
}

.pagination_item_row_count_drop_down:focus {
    box-shadow: none;
}

.pagination_item_row_count_drop_down .k-input-button .k-icon.k-i-caret-alt-down:before {
    content: none !important;
}

.pagination_item_row_count_drop_down .k-input-button .k-icon.k-i-caret-alt-down {
    background-image: url("/src/assets/common/dropdown-arrow.svg") !important;
    background-repeat: no-repeat !important;
    margin-top: 15px !important;
}