* {
  font-family: Poppins, sans-serif;
}
.depot-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.depot-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.depot-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.depot-submit-button:hover {
  background-color: #eb6e1f;
}
.depot-dialog Button {
  height: 40px;
}
.depot-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.sender-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.sender-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.sender-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.sender-submit-button:hover {
  background-color: #eb6e1f;
}
.sender-dialog Button {
  height: 40px;
}
.sender-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.permission-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.permission-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.permission-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.permission-submit-button:hover {
  background-color: #eb6e1f;
}
.permission-dialog Button {
  height: 40px;
}
.permission-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.category-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.category-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.category-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.category-submit-button:hover {
  background-color: #eb6e1f;
}
.category-dialog Button {
  height: 40px;
}
.category-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.group-user-list-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.group-user-list-div .k-checkbox {
  border-color: #ccc;
  margin-right: 10px;
}
.group-user-submit-button {
  background-color: #eb6e1f;
  border: "1px solid #EB6E1F";
  color: #fff;
}
.group-user-submit-button:hover {
  background-color: #eb6e1f;
}
.group-user-dialog Button {
  height: 40px;
}
.group-user-cancel-button {
  background-color: #fff;
  border: 1px solid #333;
}
.group-user-search-label {
  width: 188px;
  height: 21px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}
.group-user-search-box-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #cccccc;
}
.group-user-search-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  gap: 8px;
  font-family: Poppins, sans-serif;
}
.group-user-search-box-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 24px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #000000;
}
.group-user-search-box-txtbox {
  border-radius: 8px;
  border: 1px solid #cccccc;
  box-shadow: none;
}
.permission-item-title {
  font-weight: 600;
  color: #333;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 0px;
}
.permission-item-permission-group {
  line-height: 0.7em;
  font-size: 16px;
}
.image-checker-table td:first-child {
  width: 200px;
  font-weight: 600;
  color: #333;
  font-size: 16px;
  border-right: 1px solid #cccccc;
}
.image-checker-table td:last-child {
  font-weight: 400;
  color: #333;
  font-size: 16px;
  border-right: 1px solid #cccccc;
}
.image-checker-titles {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px;
  margin-top: 10px;
}
.image-checker-titles-item {
  width: 48%;
  text-align: center;
}
.image-checker-image-holders {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 40px;
  align-items: center;
  margin-top: 10px;
}
.image-checker-image-holders-item {
  width: 48%;
}
.summarycheck-route-dialog-title{
  font-size: 16px;
  font-weight: 600;
}
.summarycheck-route-email-group{
  display: flex;
  flex-wrap: wrap;
}
.summarycheck-route-email-group div{
  width: 200px;
  padding:10px;
  font-weight: 600;
}
.summarycheck-route-email-group div:nth-child(1), .summarycheck-route-email-group div:nth-child(2){
  padding-top: 0px;
}
.summarycheck-route-email-group div:nth-child(odd){
  padding-left: 0px;
}
.summary-check-route-select{
  width: 100%;
  height: 40px;
  padding: 10px 0px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}
.summary-check-route-remark-div{
  padding:10px 0px 20px 0px;
}
.summary-check-route-remark-div div:nth-child(1){
  padding:10px 0px;
  font-weight: 600;
}
.summary-check-route-button-group{
  display: flex;
  justify-content: center;
  column-gap: 10px;    
}
.summary-check-route-button-group Button{
  width: 125px;
  height: 40px;
  border-radius: 5px;
}
.summary-check-route-button-cancel{
  color: #EB6E1F;
  border:1px solid #EB6E1F;
  background-color: #fff;
}
.summary-check-route-button-submit{
  background-color: #EB6E1F;
  color: #fff;
}
.select-range-type{
  height: 40px;
  background-color: #fff;
  margin: 10px 0px;
}
.fob-report-action-taken-div{
  display: flex;
  flex-direction: column;
}
.fob-report-action-taken-close-button{
  display: flex;
  justify-content: end;
}
.fob-report-action-taken-title{
  font-size: 16px;
  font-weight: 700;
}
.fob-report-action-taken-content {
  font-size: 12px;
  font-weight: 600;
  padding: 20px 10px;
  /* padding-bottom: 20px; */
  border-bottom: 1px solid var(--antiflash-white);
}

.fob-report-action-taken-content > .fob-report-action-taken-item {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.fob-report-action-taken-content
  > .fob-report-action-taken-item
  > .fob-report-action-taken-desc {
  width: 35%;
  padding: 10px 0px;
  /* font-weight: 500; */
  border-right: 2px solid var(--antiflash-white);
}

.fob-report-action-taken-content
  > .fob-report-action-taken-item
  > .fob-report-action-taken-rolenamedate {
  width: 65%;
  padding: 10px 0px;
}