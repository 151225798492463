@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Quicksand&display=swap");
/* .logout_button p:hover {
    color: #FFA800;
  } */

.k-drawer-content {
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.navigation-div {
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  width: 240px;
  text-align: left;
  box-shadow: 0px 4px 30px 3px rgba(21, 53, 120, 0.02),
    0px 4px 20px 6px rgba(21, 53, 120, 0.05), 0px 2px 5px rgba(21, 53, 120, 0.1);
}
.custom-toolbar {
  position: absolute;
  /* width: 1897px; */
  height: 80px;
  left: 200px;
  top: 0px;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.25));
}
.custom-toolbar button {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  color: #781d7e;
}
.k-drawer-item.k-selected {
  background-color: #781d7e;
}
.k-drawer-item.k-selected:hover,
.k-drawer-item.k-selected.k-hover {
  background-color: #781d7e;
}
.k-drawer-item {
  height: 60px !important;
}
.Dashboard-Logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 46px;
  gap: 10px;
  width: 150px;
}
.bar-title {
  /* position: absolute; */
  /* width: 211px;
  height: 30px; */
  left: 12px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 700;
  /* font-size: 2/px; */
  line-height: 135%;
  /* identical to box height, or 30px */
}
.logout_popup_box {
  margin-top: 40px;
  padding: 20px 50px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  line-height: 22px;
}

.logout_popup_action_box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.logout_popup_action_button {
  border: 1px solid #781d7e;
  border-radius: 100px;
  /* color: #EB7930; */
  padding: 15px 42px;
  cursor: pointer;
}

.k-dialog {
  border-radius: 8px !important;
}

.navigation-div {
  display: flex;
  position: absolute;
  align-items: flex-start;
  width: 240px;
  text-align: left;
  box-shadow: 0px 4px 30px 3px rgba(21, 53, 120, 0.02),
    0px 4px 20px 6px rgba(21, 53, 120, 0.05), 0px 2px 5px rgba(21, 53, 120, 0.1);
}
.custom-toolbar {
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.25));
}
.custom-toolbar-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-toolbar-left button {
  color: #781d7e;
  width: 24px;
  height: 24px;
}
/* .custom-toolbar button {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  color: #781d7e;
} */
.k-drawer-item.k-selected {
  background-color: #781d7e;
}
.k-drawer-item.k-selected:hover,
.k-drawer-item.k-selected.k-hover {
  background-color: #781d7e;
}
.k-drawer-item {
  height: 60px !important;
}
.Dashboard-Logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 46px;
  gap: 10px;
  width: 150px;
}
.bar-title {
  padding: 0px 15px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 135%;
  color: #781d7e;

  /* position: absolute;
  width: 211px;
  height: 30px;
  left: 50px;
  /* bottom: 5px; */
}

.item-descr-wrap {
  display: flex;
  padding: 12px 8px 12px 24px;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}
.dropdown-btn {
  /* padding: 6px 8px 6px 16px; */
  text-decoration: none;
  font-size: 16px;
  /* color: #818181; */
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  width: 190px;
}
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.dropdown-container {
  display: block;
  /* background-color: #262626; */
  padding-left: 8px;
}
/* .logout-box{
    flex-direction: row;
    align-items: center;
    padding: 0px 25px;
    border: 1px solid #666666;
    border-radius: 8px;
    display: flex;
    right: 24.5px;
    width:100px;
    height:38px;
    cursor: pointer;
  } */
/* .logout_user{
    width: 82px;
    height: 21px;
    color: #666666;
    flex:none;
    order: 1;
    flex-grow: 0;
    font-family: 'Poppins', sans-serif;
    padding-top:5px;
  } */
/* .logout-icon{
    width: 24px;
    height: 24px;
    flex: none;
    order:2;
    flex-grow: 0;
  } */
.Drawer-submenu {
  background-color: #e7ecef;
  padding-left: 40px;
}

.app-wrapper__header {
  display: flex;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.topbar > .topbar__right {
  cursor: pointer;
}
.topbar > .topbar__left {
  display: flex;
}

.topbar > .topbar__left > .topbat__left-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-descr-wrap {
  display: flex;
  padding: 12px 8px 12px 24px;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  justify-content: space-between;
}

.dropdown-btn {
  /* padding: 6px 8px 6px 16px; */
  text-decoration: none;
  font-size: 16px;
  /* color: #818181; */
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  width: 190px;
}
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.dropdown-container {
  display: block;
  /* background-color: #262626; */
  padding-left: 8px;
}
.logout-box {
  flex-direction: row;
  align-items: center;
  padding: 0px 25px;
  /* border: 1px solid #666666; */
  /* border-radius: 8px; */
  display: flex;
  right: 24.5px;
  /* width: 100px; */
  height: 38px;
  gap: 10px;
}
.logout_user {
  /* width: 82px; */
  height: 21px;
  color: #666666;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Poppins", sans-serif;
  padding-top: 5px;
}
.logout-icon {
  width: 24px;
  height: 24px;
  flex: none;
  order: 2;
  flex-grow: 0;
}
/* .Drawer-submenu {
  padding-left: 40px;
} */

/* .Drawer-submenu-selected{
  background-color: #666666;
} */

.display-none {
  display: none;
}
.sticky_logo{
    height: 74.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid #00000014;
}
