.container {
    width: 100%;
}

.ManageCategory_create_header{
    margin: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ManageCategory_create_back{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #73007D;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.ManageCategory_create_scroll_box{
    height: calc(100vh - 245px);
    overflow: scroll;
    padding: 0px 20px;
    margin-top: 20px;
}

.ManageCategory_create_item_main_box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.ManageCategory_create_item_main_box_title{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: left;
    color: black;
}

.ManageCategory_create_item_main_box_field{
    border: 1px solid #CCCCCC;
}

.ManageCategory_create_footer_main_box{
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    bottom: 0;
    width: calc(100% - 40px);
    background: white;
    box-shadow: 0px -1px 0px 0px #00000040;
    padding: 15px 20px;
}

.ManageCategory_create_review_main_box{
    display: flex;
    gap: 10px;
}

.ManageCategory_create_footer_button_cancel{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: white;
    color: #F04A00;
    cursor: pointer;
}

.ManageCategory_create_footer_button_save{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F04A00;
    border-radius: 8px;
    padding: 0px 24px;
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    background: #F04A00;
    color: white;
    cursor: pointer;
}

