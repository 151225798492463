.icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  top: 50px;
  right: 10px;
  transform: translateY(-50%);
}

.k-input-solid {
  height: 48px !important;
}
.k-floating-label-container.k-empty > .k-label {
  top: 50% !important;
  left: 9px !important;
}
.input_label {
  text-align: left;
  margin: 0px 0px 8px 0px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.Button_group {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0px 16px;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 27px;
}

.Button_submit {
  width: 80%;
  height: 48px;
  border: 1px solid #71277a;
  background: #71277a;
  color: white;
  border-radius: 9px;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 26px;
  margin-bottom: 26px;
}

.Button_register {
  width: 49%;
  border-radius: 100px;
  height: 48px;
  border: 1px solid #71277a;
  background: white;
  color: #71277a;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* min-width: 400px; */
}

.login_media_screen {
  width: 30%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  /* background-color: #D12D2F; */
}

.forgot_password {
  cursor: pointer;
  color: #71277a;
  text-decoration: underline;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 32px;
}

@media (max-width: 769px) {
  .login_media_screen {
    width: 100%;
  }
}

@media (max-width: 426px) {
  .Button_submit,
  .Button_register {
    width: 100%;
    margin-bottom: 16px;
  }
  .Button_group {
    flex-direction: column;
  }
}

.register_header_box {
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
}

.register_header_sec_box {
  display: flex;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 2%);
  width: 48%;
}

.register_back_icon {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 12%);
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #efefef;
  margin-left: 8px;
}

.register_page_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
}

.register_header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.register_title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 32px;
  text-align: left;
}

.confirm_pass_error {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d12d2f;
  text-align: left;
  padding: 8px 0px 0px 0px;
}

.error_text {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d12d2f;
  /* text-align: left; */
  padding: 8px 0px 0px 0px;
}

.divider {
  /* height: 1px; */
  border: 1px solid #e4e1de;
  background-color: #e4e1de;
}

.Register_dropdown {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px !important;
  height: 48px !important;
}

@media (max-width: 769px) {
  .register_header_sec_box {
    width: 100%;
    align-items: center;
    height: 100%;
  }
  .register_title {
    margin-top: 16px;
  }
}

@media (min-width: 769px) {
  .register_header_box {
    display: none;
  }
  .register_title {
    margin-top: 25px;
  }
}

.Forgot_text {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: left;
}

/* .forgot_detail_box{
    width: 50%;
    margin: auto;
}

@media (max-width: 769px) {
    .forgot_detail_box{
        width: 100%;
        margin: none;
    }
} */

.login-wrapper {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/authentication/cityscape-singapore-city-skyline.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.login_media_screen {
  background-color: #ffffff;
}

.forgot_password_main_box{
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  margin: 20px 25%;
}

.forgot_password_title{
  width: 100%;
  text-align: left;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.forgot_password_detail_main_box{
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.forgot_password_detail_title{
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.forgot_password_detail_field{
  width: calc(100% - 20px);
  border: 1px solid rgb(228, 225, 222);
  border-radius: 8px;
  height: 48px;
  padding: 0px 10px;
  outline: none;
}

.forgot_password_detail_field:focus{
  box-shadow: 0 0 0 2px rgba(0,0,0,.08);
}

.forgot_password_detail_field:focus-visible{
  border-color: transparent;
}

.forgot_password_footer_main_box{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.forgot_password_footer_button{
  width: calc(50% - 15px);
  border-radius: 9px;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot_password_footer_cancel{
  border: 1px solid #71277a;
  background: white;
  color: #71277a;
}

.forgot_password_footer_proceed{
  border: 1px solid #71277a;
  background: #71277a;
  color: white;
}

.forgot_password_hint_main_box{
  text-align: left;
  margin-top: 20px;
}

.forgot_password_hint_title{
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.forgot_password_hint_sub_title{
  font-family: Poppins, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
}

.forgot_password_hint_list{
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.forgot_password_hint_footer{
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.forgot_password_icon{
  cursor: pointer;
  box-sizing: border-box;
  height: 1.5rem;
  padding: 4px;
  position: absolute;
  top: 25px;
  transform: translateY(-50%);
  width: 1.5rem;
  z-index: 1;
  right: 15px;
}

.forgot_password_error{
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-top: 10px;
}

.forgot_password_error_box{
  font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    background: red;
    width: calc(100% - 50%);
    margin: 0px 25%;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /* position: absolute; */
}

.forgot_password_error_movedown{
  animation: MoveDown 5s linear;

}

@keyframes MoveDown {
  0% {
    height: 0px;
  }
  5%{
    height: 40px;
  }
  25% {
    height: 40px;
  }
  50%{
    height: 40px;
  }
  75%{
    height: 40px;
  }
  95%{
    height: 40px;
  }
  100%{
    height: 0px;
  }
}