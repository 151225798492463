.dr-stepper {
  width: 100% !important;
  left: 0px !important;
}

.dr-stepper .k-step-link {
  position: relative;
}

.dr-stepper .k-progressbar {
  border: 1px solid #cccccc;
  left: 8px;
}

.dr-step-info {
  margin-left: 25px;
  margin-bottom: 25px;
}

.dr-stepper .k-step:nth-child(1) .dr-step-circle {
  background-color: #e9465e;
}

.dr-step-circle {
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #cccccc;
}

.dr-step-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  line-height: 130%;
}

.dr-step-description {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  display: flex;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 130%;
}

.dr-step-creator {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #666666;
  line-height: 140%;
}

.dr-step-submitted-on {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #666666;
  line-height: 140%;
}
