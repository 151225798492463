.table-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 26px;
    gap: 16px;
    /* width: 1227px; */
    width: calc(100vw - 315px);
    height: 100px;
    background: #FFFFFF;
    border-radius: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-top: 1px solid #F6F6F6;
}

.table-header-div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 182px;
    /* width: 1044px; */
    height: 48px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.table-filter-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  width: 1219px;
  height: 48px;
  background-color: #E7ECEF;
  margin: 0px 10px 10px 10px;
  border-radius: 8px;
  gap: 30px;
}

.table-filter-item{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.table-filter-title{
  font-family: Poppins, sans-serif;
  font-weight: 600;

}
.table-filter-clear{
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color:#F04A00;
  font-weight: 500;
  text-decoration: underline;
  line-height: 22px;
  cursor: pointer;
}
.table-name{
    width: 700px;
    height: 30px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 114%;
    color: #333333;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}
.buttons_group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 344px;
    height: 48px;
    border-radius: 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.addnew_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 169px;
    height: 48px;
    background: #EB6E1F !important;
    color:white !important;
    font-weight: 600;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .button2{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 46px;
    height: 48px;
    background: #FFFFFF !important;
    border: 1px solid #781D7E !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px !important;
    flex: none;
    order: 2;
    flex-grow: 0;

    background-color: white !important;
    box-shadow: none;
    max-width: 38px;
    max-height: 38px;
  }
  .button2 > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tbody-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    /* width: 1279px; */
    /* height: 710px; */
    border-radius: 0px 0px 0px 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .k-i-sort-desc-small::before{
    content: "" !important;
  }
  .table-action-btn{
    background: #EB6E1F;
    width: "128px";
  }
  .k-link{
    word-break: break-all !important;
    word-wrap: break-word !important;
  }
  .k-column-title{
    font-weight: 600;
    font-size: 14px;
    font-family: Poppins,sans-serif;
    font-style: normal;
    background-color: #F7F7F7;
    overflow: unset !important;
    word-break: break-word !important;
    text-overflow: unset !important;
    white-space: normal !important;
    min-width: 50px !important;
  }
  .k-i-sort-asc-small::before{
    content: url("/src/assets/common/unsorted.svg") !important;
  }
 .k-grid-pager{
  justify-content: space-evenly;
 }
 /* .k-pager-numbers{
  background-color: #EB6E1F;
  border-radius: 8px;
 }
 .k-pager-numbers button{
  color: #FFFFFF;
 } */
  .k-table-td,.k-table-th{
    border-right: 1px solid #D2DAE9 !important;
  }
  .k-table-td{
    background-color: #FFFFFF !important;
  }
  .action-btn-dropdown{
    background-color: #FFFFFF !important;
    /* border-color: #333333; */
  }
  .notification-table{
    width: 100%;
  }
  .notification-table .k-picker-solid:focus-within{
    background-color: #EB6E1F !important;
    color: #FFFFFF !important;
  }
  .td-align-center{
    text-align: center !important;
  }
  .notification-table .k-table-th{
    vertical-align: middle !important;
  }
  .table-custom-pagination{
    width:100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .pagination-div{
    width: 300px;
    /* background-color: #781D7E; */
    display: flex;
    flex-direction: row;
  }
.table-custom-pagination .k-selected{
  background-color: #EB6E1F !important;
  color:#FFFFFF !important;
  border-radius: 8px !important;
}

.telephone-table{
  width: 100%;
}

.telephone-table td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background-color: #ffffff;
  border-right: 1px solid #D2DAE9 !important;
}

.drTable{
  width: 100%;
}

.drTable td {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background-color: #ffffff;
  border-right: 1px solid #D2DAE9 !important;
}